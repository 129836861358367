<template>
  <div class="container">
    <!-- <div class="top-bar">
      <div class="right-bar"></div>
      <div class="left-bar"></div>
      <div class="return-btn">
        <span @click="$router.back(-1)" class="return-text">
          <i class="v-icon-back return-icon"></i>
          返回上一级
        </span>
      </div>-->
    <div v-if="isStationNav" class="select-options">
      <el-select
        v-model="currentStationId"
        @change="selectChange"
        placeholder="请选择"
      >
        <el-option
          v-for="item in navItems"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <!-- </div>  -->
    <div class="d-flex">
      <div class="caption-title">
        <i class="fl"><el-image :src="leftUrl" /></i>
        <span class="biaoti fl">{{ zdName }}</span>
        <i class="fr"><el-image :src="rightUrl" /></i>
      </div>
    </div>
    <div class="d-flex">
      <el-button
        class="button-3d-1"
        :class="visiable == 1 ? 'active' : ''"
        @click="clickBtn(1)"
        >变压器</el-button
      >
      <el-button
        class="button-3d-1"
        :class="visiable == 2 ? 'active' : ''"
        @click="clickBtn(2)"
        >开关柜</el-button
      >
      <el-button
        class="button-3d-1"
        :class="visiable == 3 ? 'active' : ''"
        @click="clickBtn(3)"
        >电力电缆</el-button
      >
    </div>
    <div class="content">
      <div class="content-box">
        <div class="byq-chart">
          <div style="width: 400px">
            <hx :cdata="cdata2" :id="'yibiaochart'" />
          </div>
          <div style="width: 400px">
            <hx :cdata="cdata3" :id="'zhuzhuang'" />
          </div>
        </div>
        <div class="byq-list" v-if="visiable == 1">
          <div
            class="byq-item"
            v-for="(rect, index) in deviceList"
            :key="index"
            @click="clickEvent(rect)"
          >
            <div class="byq-name">
              {{ rect.equipment_name }}
            </div>
          </div>
        </div>
        <div
          class="byq-list"
          style="align-content: flex-start"
          v-if="visiable == 2"
        >
          <div
            class="wrap"
            v-for="(rect, index) in deviceList"
            :key="index"
            @click="clickEvent(rect)"
          >
            <div
              class="trangel"
              :style="{
                'background-image': 'url(' + kggImage[rect.status] + ')',
                'background-size': 'cover',
              }"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div class="span-title">{{ rect.equipment_name }}</div>
            </div>
          </div>
        </div>
        <div
          class="byq-list"
          style="align-content: flex-start"
          v-if="visiable == 3"
        >
          <div
            class="dl-item"
            :style="{ 'background-image': 'url(' + dlImage[rect.status] + ')' }"
            v-for="(rect, index) in deviceList"
            :key="index"
            @click="clickEvent(rect)"
          >
            <div class="dl-name">
              {{ rect.equipment_name }}
            </div>
          </div>
        </div>
        <div class="down" v-if="visiable == 2">
          <el-image
            @click="tap('preview')"
            style="width: 320px; height: 100%"
            :src="url"
            fit="cover"
            :preview-src-list="urlList"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="container-x">
      <div
        v-for="(block, index) in blocks"
        :key="index"
        :class="`${block.isActive == true ? block.classActiveName : 'block'}`"
        :style="{ top: block.positionY + 'px', left: block.positionX + 'px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import TopoRender from "./components/TopoRender";
import eventBus from "@/utils/eventBus.js";
import hx from "./components/hxecharts.vue"; // 导入prpd组件
import {
  getByqListByZdId,
  getKggListByZdId,
  getDlListByZdId,
  siteTransformer,
} from "@/api/service";
export default {
  name: "DynamicRectanglesLayout",
  data() {
    return {
      navItems: [],
      currentStationId: null,
      zdName: "站点名称xx",
      url: require("./image/wiringDiagram.png"),
      urlDL: require("./image/dianlan.png"),
      leftUrl: require("./image/title-left.png"),
      rightUrl: require("./image/title-right.png"),
      urlList: ["/images/wiringDiagram.png"],
      visiable: 1,
      innerDialogVisible: false,
      dialogVisible: false,
      deviceList: [],
      kggImage: {
        0: require("./image/kgg-0.png"),
        1: require("./image/kgg-1.png"),
        2: require("./image/kgg-2.png"),
      },
      dlImage: {
        0: require("./image/dl-0.png"),
        1: require("./image/dl-1.png"),
        2: require("./image/dl-2.png"),
      },
      detailInfo: [],
      equipmentName: "",
      cdata2: {},
      cdata3: {},
      // svg_data: ""
      // 加进来的
      imageSrc: require("./image/byq-1.png"),
      srcRed: require("./image/red.png"),
      warningTEV: "正常",
      warningAE: "预警",
      warningHFCT: "关注",
      newTime: "",
      t_max1: 123,
      srcGreen: require("./image/green.png"),
      srcYellow: require("./image/yellow.png"),
      blocks: [],
      blocks1: [
        // 可以根据需要添加更多的块，‌并设置不同的位置
      ],
      blocks2: [
        // 可以根据需要添加更多的块，‌并设置不同的位置
      ],
      blocks3: [
        // 可以根据需要添加更多的块，‌并设置不同的位置
      ],
    };
  },
  components: { TopoRender, hx },
  filters: {
    getFilters(value) {
      if (value == 0) {
        return "正常";
      } else {
        return "异常";
      }
    },
  },
  computed: {
    setBackgroundImage() {
      return {
        backgroundImage: this.condition
          ? "url(/path/to/image1.jpg)"
          : "url(/path/to/image2.jpg)",
      };
    },
    isStationNav() {
      return !this.$route.query.stationName;
    },
  },

  async mounted() {
    await this.getStationList();

    console.log();
  },
  methods: {
    async getStationList() {
      await siteTransformer().then((res) => {
        if (res.code == 200) {
          let arr = [];
          if (res.data.length > 0) {
            res.data.map((item) => {
              arr.push({
                text: item.site_name,
                value: item.site_transformer_id,
              });
            });
          }
          this.navItems = arr;
          if (this.$route.query.stationName) {
            this.zdName = this.$route.query.stationName;
          } else {
            this.zdName = this.navItems[0].text;
            this.currentStationId = this.navItems[0].value;
            console.log(this.navItems, "this.$route.query.");
          }
          this.getByqList();
        }
      });
    },
    selectChange(value) {
      let deal = this.navItems.filter((item) => item.value == value);
      console.log(deal, "value");
      this.zdName = deal[0].text;
      this.currentStationId = value;
      this.clickBtn(1);
    },
    // 根据行索引返回该行应有的矩形数量
    rectCount(index) {
      return this.rects[index] || 0; // 如果rects中没有对应索引的值，则返回0
    },
    clickEvent(value) {
      let equipmentType;
      if (this.visiable == 1) {
        equipmentType = "byq";
      }
      if (this.visiable == 2) {
        equipmentType = "kgg";
      }
      if (this.visiable == 3) {
        equipmentType = "dl";
      }
      // this.$router.push({
      //   path: "/statusMng/statusMng",
      //   query: { equipmentId: value.test_equipment_id, equipmentType },
      // });
      eventBus.$emit("message", {
        id: this.findIndexOfMenuNamed(
          JSON.parse(sessionStorage.getItem("permissions")),
          "/statusMng"
        ),
        path: {
          path: "/statusMng/statusMng",
          query: { equipmentId: value.test_equipment_id, equipmentType },
        },
      });
    },
    clickBtn(value) {
      switch (value) {
        case 1:
          this.getByqList();
          break;
        case 2:
          this.getKggList();
          break;
        case 3:
          this.getDlList();
          break;

        default:
          break;
      }
      this.visiable = value;
      this.blocks.forEach((block) => {
        block.isActive = false;
        // 如果需要，‌也可以在这里动态调整位置
      });
    },
    // 查找station在数组中的下标
    findIndexOfMenuNamed(array, attributeName) {
      const filteredMenuArray = array.filter(
        (item) => item.attribute === "menu"
      );
      for (let i = 0; i < filteredMenuArray.length; i++) {
        console.log(filteredMenuArray[i].remarks, attributeName);
        if (filteredMenuArray[i].remarks === attributeName) {
          return i; // 返回在过滤后的子集中的索引
        }
      }
      return -1; // 如果没有找到，返回-1
    },
    // 点击调用放大图片的方法，进行预览
    tap(attr) {
      this.dialogVisible = true;
    },
    //显示详情
    showDetail(param) {
      this.equipmentName = param.equipment_name;
      getPresentDataById({ test_equipment_id: param.test_equipment_id }).then(
        (res) => {
          if (res.code == 200) {
            res.data.map((item) => {
              if (item.data_line.length == 0) {
                //这里是为了让页面不报错坐的处理
                item.data_line[0] = {};
              }
            });
            this.detailInfo = [...res.data];
            this.innerDialogVisible = true;
            this.imageSrc = require("./image/kgg-2.png");
            if (res.data.length > 0) {
              console.log(res.data, "拿到的数据");
              // 此版本对接以传感器类型分组的数组式传感器数据
              res.data.forEach((v) => {
                if (v.sensor_type == "tev") {
                  this.tevdata = v.data_line;
                } else if (v.sensor_type == "ae") {
                  this.aedata = v.data_line;
                } else if (v.sensor_type == "hfct") {
                  this.hfctdata = v.data_line;
                } else if (v.sensor_type == "uhf") {
                  this.uhfdata = v.data_line;
                } else if (v.sensor_type == "tem") {
                  this.temdata = v.data_line;
                }
              });
            }
          }
        }
      );
    },
    //获取变压器列表
    getByqList() {
      let param;
      if (this.$route.query.stationId) {
        param = this.$route.query.stationId;
      } else {
        param = this.currentStationId;
      }
      getByqListByZdId(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.deviceList = res.data;
          this.setupData(res.data);
        } else {
          this.deviceList = [];
        }
      });
    },
    //获取开关柜列表
    getKggList() {
      let param;
      if (this.$route.query.stationId) {
        param = this.$route.query.stationId;
      } else {
        param = this.currentStationId;
      }
      getKggListByZdId(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.deviceList = res.data;
          this.setupData(res.data);
        } else {
          this.deviceList = [];
        }
      });
    },
    //获取电缆列表
    getDlList() {
      let param;
      if (this.$route.query.stationId) {
        param = this.$route.query.stationId;
      } else {
        param = this.currentStationId;
      }
      getDlListByZdId(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.deviceList = res.data;
          this.setupData(res.data);
        } else {
          this.deviceList = [];
        }
      });
    },

    setupData(value) {
      console.log(value, "设备列表");
      let tenNum = 0;
      let sixNum = 0;
      let threeNum = 0;
      if (value.length > 0) {
        value.map((item) => {
          if (item.voltage_level == "10kV") {
            tenNum++;
          } else if (item.voltage_level == "6.6kV") {
            sixNum++;
          } else if (item.voltage_level == "3.5kV") {
            threeNum++;
          }
        });
      }
      let colorArray = [
        {
          top: "#ffa800", //黄
          bottom: "rgba(11,42,84,.3)",
        },
        {
          top: "#1ace4a", //绿
          bottom: "rgba(11,42,84, 0.3)",
        },
        {
          top: "#4bf3ff", //蓝
          bottom: "rgba(11,42,84,.3)",
        },
      ];
      this.cdata3 = {
        backgroundColor: "transparent",
        tooltip: {
          show: true,
          formatter: "{b}{c}台",
        },
        grid: {
          left: "5%",
          top: "12%",
          right: "20%",
          bottom: "8%",
          containLabel: true,
        },

        xAxis: {
          type: "value",
          show: false,
          position: "top",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            type: "category",
            axisTick: {
              show: false,
              alignWithLabel: false,
              length: 10,
            },
            axisLabel: {
              color: "#fff200",
              fontSize: 16, // 设置字体大小为16
            },
            splitLine: {
              //网格线
              show: false,
            },
            inverse: "true", //排序
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff",
              },
            },
            data: ["10kV:", "6.6kV:", "3.5kV:"],
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            label: {
              normal: {
                show: true,
                position: "right",
                formatter: "{c}台",
                textStyle: {
                  color: "white", //color of value
                  fontSize: 18,
                },
              },
            },
            itemStyle: {
              normal: {
                show: true,
                color: function (params) {
                  let num = colorArray.length;
                  return {
                    type: "linear",
                    colorStops: [
                      {
                        offset: 0,
                        color: colorArray[params.dataIndex % num].bottom,
                      },
                      {
                        offset: 1,
                        color: colorArray[params.dataIndex % num].top,
                      },
                      {
                        offset: 0,
                        color: colorArray[params.dataIndex % num].bottom,
                      },
                      {
                        offset: 1,
                        color: colorArray[params.dataIndex % num].top,
                      },
                      {
                        offset: 0,
                        color: colorArray[params.dataIndex % num].bottom,
                      },
                      {
                        offset: 1,
                        color: colorArray[params.dataIndex % num].top,
                      },
                      {
                        offset: 0,
                        color: colorArray[params.dataIndex % num].bottom,
                      },
                      {
                        offset: 1,
                        color: colorArray[params.dataIndex % num].top,
                      },
                      {
                        offset: 0,
                        color: colorArray[params.dataIndex % num].bottom,
                      },
                      {
                        offset: 1,
                        color: colorArray[params.dataIndex % num].top,
                      },
                      {
                        offset: 0,
                        color: colorArray[params.dataIndex % num].bottom,
                      },
                      {
                        offset: 1,
                        color: colorArray[params.dataIndex % num].top,
                      },
                    ],
                    //globalCoord: false
                  };
                },
                barBorderRadius: 70,
                borderWidth: 0,
                borderColor: "#333",
              },
            },
            barGap: "0%",
            barCategoryGap: "60%",
            data: [
              {
                name: "10kV:",
                value: tenNum,
                symbolPosition: "end",
                itemStyle: {
                  normal: {
                    color: "#00fff5", //圆柱顶部颜色
                  },
                },
              },
              {
                name: "6.6kV:",
                value: sixNum,
                symbolPosition: "end",
                itemStyle: {
                  normal: {
                    color: "#00fff5", //圆柱顶部颜色
                  },
                },
              },
              {
                name: "3.5kV:",
                value: threeNum,
                symbolPosition: "end",
                itemStyle: {
                  normal: {
                    color: "#b9b7ff", //圆柱顶部颜色
                  },
                },
              },
            ],
          },
        ],
      };
    },
    toggleBlocks() {
      if (this.visiable == 1) {
        this.blocks = this.blocks1;
      } else if (this.visiable == 2) {
        this.blocks = this.blocks2;
      } else if (this.visiable == 3) {
        this.blocks = this.blocks3;
      }
      this.blocks.forEach((block) => {
        block.isActive = !block.isActive;
        // 如果需要，‌也可以在这里动态调整位置
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: calc(100vh - 160px); /* 减去内边距，使内容尽可能居中 */
  width: 100%;
}
.d-flex {
  display: flex;
  justify-content: center; /* 水平居中矩形 */
  gap: 50px; /* 矩形之间的间隙 */
  width: fit-content; /* 宽度适应内容 */
  margin: 0 auto; /* 如果需要，可以进一步水平居中行（在这个例子中可能不是必需的） */
}
.content {
  width: 100%;
  height: calc(100vh - 280px);
}

.down {
  margin-top: 10%;
  width: 20%;
  height: 40%;
}
.content-box {
  display: flex;
  // flex-wrap: wrap;
  height: 100%;

  .el-image {
    padding: 20px;
    height: 100%;
    size: 100%;
  }
}
.byq-chart {
  width: 20%;
}
.byq-list {
  width: 100%;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
  gap: 16px;
}
.byq-item {
  width: 200px;
  height: 200px;
  background-image: url(./image/byq-zuhe.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.dl-item {
  width: 100px;
  height: 180px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.row {
  display: flex;
  height: 70%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; /* 水平居中矩形 */
  gap: 10px; /* 矩形之间的间隙 */
  // width: fit-content; /* 宽度适应内容 */
  padding: 0 100px 30px 50px; /* 如果需要，可以进一步水平居中行（在这个例子中可能不是必需的） */
  .dianlan-image {
    color: #fff;
    margin-left: 30%;
    font-size: 14px;
    height: 100%;
    .el-image {
      padding: 20px;
      size: 100%;
    }
  }
  .tuli {
    position: absolute;
    top: 7%;
    right: 12%;
    width: 400px;
    height: 150px;
    background-image: url(./image/title.png);
  }
}
.btn {
  width: 200px;
  height: 100px;
  position: relative;
  background-image: url(./image/btn-unselect.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 16px;
}
.btned {
  width: 250px;
  height: 100px;
  position: relative;
  background-image: url(./image/btn-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 16px;
}
.caption-title {
  font-family: "黑体";
  color: #fff;
  max-width: 900px;
  margin: 0 auto;
  height: 48px;
  margin-top: 10px;
}
.biaoti {
  line-height: 48px;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  width: 276px;
  text-align: center;
  font-size: 36px;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.d-text1 {
  padding: 0 0 0 100px;
}
.d-text {
  font-size: 18px;
  color: #dddde4;
  margin: 40px;
  padding: 50px 0 0 30px;
  line-height: 100px;
}
.rect {
  width: 150px; /* 矩形宽度 */
  height: 200px; /* 矩形高度 */
  background-color: #065f6e80; /* 矩形背景颜色 */
  border: 1px solid #6aa1f3a6;
}
.guzhang {
  width: 150px; /* 矩形宽度 */
  height: 200px; /* 矩形高度 */
  background-color: #cf544b80; /* 矩形背景颜色 */
  border: 1px solid #a35d34a6;
}
.name-title {
  color: rgb(231, 236, 255);
  font-size: 20px;
  margin-top: 10px;
  margin-left: 30px;
}
.span-title {
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin-top: 90px;
}
.top-bar {
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #093c77;
  }
}
.right-bar {
  position: absolute;
  left: 10px;
  height: 5px;
  width: 100%;
  border-top: 1px solid #2681ff;
  border-left: 2px solid #2681ff;
  background: rgba(55, 126, 255, 0.04);
  border-top-left-radius: 5px;
  transform: skewX(-45deg);
  box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
}

.left-bar {
  position: absolute;
  left: 0;
  top: 2px;
  height: 50px;
  width: 152px;
  border-right: 1px solid #2681ff;
  border-bottom: 1px solid #2681ff;
  transform: skewX(-45deg);
  border-bottom-right-radius: 5px;
  box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
}

.return-btn {
  position: absolute;
  left: -31px;
  top: 0;
  width: 180px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: #151b22;
  cursor: pointer;
  transform: skewX(-45deg);
  border-bottom-right-radius: 5px;
}
.select-options {
  position: absolute;
  right: 31px;
  top: 10px;
}
.return-text {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  transform: skewX(45deg);
  transition: 0.2s;

  &:hover {
    color: #60fafa;
  }
}

.return-icon {
  margin-right: 5px;
}
.wrap {
  width: 100px;
  height: 150px;
  background-size: 100% 100%;
  margin-bottom: 40px;
  position: relative;
  -webkit-perspective-origin: right top;
  perspective-origin: right top;
  /*可见面*/
  -webkit-perspective: 600px;
  perspective: 600px;
  /*可见立体长度*/
}

.btn-groups {
  position: absolute;
  right: 0;
  top: 30%;
  width: 120px;
  height: 400px;
  min-height: calc(100vh - 300px);
}
.button-3d-1 {
  display: flex;
  height: 40px;
  margin: 20px 0px;
  position: relative;
  background: rgb(3, 139, 250);
  border: none;
  color: rgb(209, 248, 243);
  font-size: 16px;
  box-shadow: -6px 6px 0 hsl(214, 100%, 35%);

  &:hover,
  &:active {
    background: hsl(16, 100%, 40%);
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 hsl(16, 100%, 30%);
  }
}
.active {
  background: hsl(16, 100%, 40%);
  top: 3px;
  left: -3px;
  box-shadow: -3px 3px 0 hsl(16, 100%, 30%);
}

.trangel {
  width: 100px;
  height: 150px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /*编制3D舞台效果*/
  transition: 1s all;
  /*建立一秒的过渡属性*/
}

.trangel div {
  position: absolute;
  width: 100px;
  height: 180px;
  color: #fff;
  line-height: 140px;
  text-align: center;
}

.trangel div {
  position: absolute;
  width: 100px;
  height: 180px;
  color: #fff;
  line-height: 130px;
  text-align: center;
}

.trangel div:nth-of-type(1) {
  width: 100px;
  height: 80px;
  left: 0;
  top: -95px;
  background: -webkit-radial-gradient(rgba(33, 162, 248, 0.5), #2247dd33);
  border: solid 1px rgb(32, 248, 255);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  /*设置旋转元素的基点位置：从底部开始*/
  -webkit-transform: rotateX(85deg);
  transform: rotateX(89deg);
  box-shadow: -1px -1px 9px #f8e535;
  /*垂直旋转90°*/
}

.trangel div:nth-of-type(2) {
  top: 0;
  background: -webkit-radial-gradient(rgba(54, 93, 223, 0.5), #12fa2533);
  border: solid 1px rgb(32, 248, 255);
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transform: rotateY(-95deg);
  transform: rotateY(-92deg);
}

.trangel div:nth-of-type(3) {
  background: -webkit-radial-gradient(rgba(82, 154, 248, 0.5), #12fa2533);
  border: solid 1px rgb(13, 216, 223);
  left: 0px;
  top: 0;
}

.trangel div:nth-of-type(4) {
  width: 100px;
  height: 80px;
  left: 0px;
  top: 180px;
  background: -webkit-radial-gradient(rgba(26, 26, 25, 0.5), #0c29ac33);
  border: solid 1px rgb(6, 15, 94);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform: rotateX(-89deg);
  transform: rotateX(-87deg) rotateZ(-2deg);
  box-shadow: -4px 10px 9px #ffffff;
}

.trangel div:nth-of-type(5) {
  top: 0px;
  background: -webkit-radial-gradient(rgba(20, 55, 212, 0.5), #0f3bcc33);
  border: solid 1px rgb(6, 15, 94);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: rotateY(88deg);
  transform: rotateY(88deg);
}

.trangel div:nth-of-type(6) {
  left: 0px;
  top: 0px;
  background: -webkit-radial-gradient(rgba(214, 215, 219, 0.5), #d6d7db33);
  border: solid 1px rgb(6, 15, 94);
  -webkit-transform: translateZ(-100px) rotateX(180deg);
  transform: translateZ(-90px) rotateX(180deg) translateX(4px);
}
.trangel div:nth-of-type(7) {
  width: 100px;
  left: 0;
  top: -180px;
  background: -webkit-radial-gradient(rgba(114, 137, 230, 0.5), #2247dd33);
  border: solid 1px rgb(6, 15, 94);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  /*设置旋转元素的基点位置：从底部开始*/
  -webkit-transform: rotateX(85deg);
  transform: rotateX(85deg);
  /*垂直旋转90°*/
}
::v-deep .custom-dialog {
  // min-height: 600px;
  background-image: url("./image/kgg-2.png"); /* 设置背景图像 */
  background-repeat: no-repeat; /* 防止背景图像重复 */
  background-position: center center; /* 背景图像居中对齐 */
  background-color: #062b7cc2;
  border: 1px solid #409eff;
}
::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #409eff;
}
::v-deep .el-dialog__title {
  color: #fff;
}
::v-deep .el-descriptions__title {
  color: #fff;
}

::v-deep .el-descriptions-item__content {
  min-width: 100px;
  color: #fff;
}
::v-deep .el-descriptions__body {
  background-color: transparent;
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background-color: transparent;
  color: #fff;
}
.container-x {
  position: absolute; /* 添加此行以便子元素可以使用绝对定位 */
  width: 80%; /* 或者你想要的宽度 */
  z-index: -999;
  height: calc(100vh - 220px); /* 或者你想要的高度 */
  top: 50px;
  margin-top: 20px;
}

.block {
  position: absolute; /* 修改此行以便可以使用top和left属性 */
  width: 200px;
  height: 250px;
  background-color: transparent;
  transition: background-color 0.5s, transform 0.5s; /* 添加transform过渡 */
}

.blockX {
  position: absolute; /* 修改此行以便可以使用top和left属性 */
  width: 190px;
  height: 220px;
  transition: background-color 0.5s, transform 0.5s; /* 添加transform过渡 */
  background-color: rgba(78, 247, 143, 0.3);
  animation: fall 0.5s forwards;
}

.blockKgg {
  position: absolute; /* 修改此行以便可以使用top和left属性 */
  width: 100px;
  height: 180px;
  transition: background-color 0.5s, transform 0.5s; /* 添加transform过渡 */
  background-color: rgba(255, 218, 5, 0.3);
  animation: fall 0.5s forwards;
}

.blockDL {
  position: absolute; /* 修改此行以便可以使用top和left属性 */
  width: 20px;
  height: 100px;
  transition: background-color 0.5s, transform 0.5s; /* 添加transform过渡 */
  background-color: rgba(190, 255, 104, 0.3);
  animation: fall 0.5s forwards;
}
@keyframes fall {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
::v-deep .el-select {
  width: 200px;
}
::v-deep .el-input__inner {
  background-color: rgba(0, 32, 136, 0.2);
  color: #fff;
}
::v-deep .el-select-dropdown__list {
  color: #fff;
}
::v-deep .el-select-dropdown__item.hover {
  background-color: rgba(0, 32, 136, 0.2);
}
::v-deep .el-select-dropdown__item:hover {
  background-color: rgba(0, 32, 136, 0.8);
}
.byq-name {
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin-top: 150px;
}

.dl-name {
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin-top: 130px;
}
</style>
