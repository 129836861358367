import request from './request'
let base = 'http://47.104.237.15:9901/' //本地
// let base = 'http://127.0.0.1:9901/' //本地
// let base = 'http://192.168.2.21:9901/' //服务器
// 这是业务链接
// （1）站点查询变压器下的被检测设备信息
export const getByqListByZdId = (zd_id) => {
    return request({
        url: `${base}API_V1.0/collection/equipment_type_suffer_b/${zd_id}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// （2）站点查询开关柜下的被检测设备信息
export const getKggListByZdId = (zd_id) => {
    return request({
        url: `${base}API_V1.0/collection/equipment_type_suffer_k/${zd_id}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// （3）站点查询电缆下的被检测设备信息
export const getDlListByZdId = (zd_id) => {
    return request({
        url: `${base}API_V1.0/collection/equipment_type_suffer_d/${zd_id}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// 被检测设备信息查询
export const getEquipmentInfo = (equipment_id) => {
    return request({
        url: `${base}API_V1.0/collection/test_suffer_equipment_view/${equipment_id}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

//历史数据
///API_V1.0/collection/test_suffer_equipment_history
export const getEquipmentHistory = (params) => {
    return request({
        url: `${base}API_V1.0/collection/test_suffer_equipment_history`,
        method: 'post',
        data: params,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
///API_V1.0/collection/test_suffer_equipment_real
//状态管理下的实时数据
export const getEquipmentReal = (params) => {
    return request({
        url: `${base}API_V1.0/collection/test_suffer_equipment_real_`,
        method: 'post',
        data: params,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

// 所有包含站点的城市
export const cityLevelView = () => {
    return request({
        url: `${base}API_V1.0/collection/city_level_view`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
}; 


// 某城市的变电站
export const cityLevelSiteView = (param) => {
    return request({
        url: `${base}API_V1.0/collection/city_level_site_view/${param}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
//条件查询被检测设备
export const queryEquipmentlist = (params) => {
    return request({
        url: `${base}API_V1.0/collection/test_suffer_equipment_screen`,
        method: 'post',
        data: params,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
//条件查询被检测设备
export const siteTransformer = () => {
    return request({
        url: `${base}API_V1.0/collection/site_transformer`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
//位置英文转中文
export const sensorPosition = () => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters/sensor_position`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};




