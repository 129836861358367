<template>
  <div>
    <Echart
      :options="options"
      :id="id"
      :height="'220px'"
      :width="'100%'"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {
      },
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      dafault: "echartsi",
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = newData;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    formatter(params) {
      let temp = [];
      let temp2 = "";
      let leftSide = "";
      let rightSide = "";
      for (let item of params) {
        temp.push({
          seriesName: item.seriesName,
          value: item.value,
        });
      }
      let arr = temp.sort(this.sortValue);
      let midValue = Math.ceil(arr.length / 2);
      for (let [key, item] of arr.entries()) {
        if (key < midValue && midValue > 2) {
          leftSide += `${item.seriesName}: [${item.value}]<br>`;
        } else {
          rightSide += `${item.seriesName}: [${item.value}]<br>`;
        }
      }
      temp2 = `<div style="display: flex">
            <div>${leftSide}</div>
            <div style="margin-left:20px;">${rightSide}</div>
            </div>`;
      return temp2;
    },
  },
};
</script>
